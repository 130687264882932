import React from "react";
import Footer from "../Footer/Footer";
import img1 from "../Image/Header1.png";
import { FaDiscord } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { BsMedium } from "react-icons/bs";
import "./Main.css";
import MainSlider from "./MainSlider";
const Main = () => {
  return (
    <div className="main">
      <a name="about"></a>
      <div className="container">
        <div className="col-10 mx-auto px-3">
          {/* <div className="icon-part d-flex justify-content-center mt-5">
            <div className="twitter mx-3">
              <a href="https://twitter.com/FoxNationDAO" target="_blank">
                <BsTwitter
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
            <div className="discord mx-3">
              <a href="https://discord.com/invite/foxnationdao" target="_blank">
                <FaDiscord
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
            <div className="medium mx-3">
              <a href="https://medium.com/@FoxNationDAO" target="_blank">
                <BsMedium
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
          </div> */}

          <div className="main-img-center text-center">
            {/* <a href="https://medium.com/@FoxNationDAO" target="_blank">
            <img
              src={img1}
              alt=""
              className="img-center mb-5 text-center"
              data-aos="fade-up"
            />
          </a> */}

            {/* <div className="main-text text-center mb-3">
              <div className="main-text-title mb-4" data-aos="fade-up"> */}
            <div className="title text-center py-5">
              <div className="title-main mb-5">WITCH TOWN</div>
              <div className="description">
                The Witches are coming to help the degens to get rid of the
                Bear. <br />
                They bring a magic potion and one lucky holder will get the 50%
                of total secondary sales collected in the 1st week. <br />
                Magic Potion to claim for holders, turn your witch in a
                princess.
                <br />
                {/* Witch Town is a collection of 9,999 assets algorithmically
                generated and issued on Ethereum. <br /> */}
                <br />
                9,999 max supply. Check our smart contract{" "}
                <a
                  href="https://etherscan.io/address/0xa6ec553ebcfcfbfbf2d31b361365bad2c4ac9cad#code"
                  target="_blank"
                >
                  here
                </a>
                .{" "}
                {/* Magic Potion to claim for holders, turn your witch in a princess{" "}
                <br /> <br />
                10 ETH Raffle: 1 lucky holder will get 10 ETH */}
                {/* Check our{" "}
                <a
                  className="main-link"
                  href="https://medium.com/@WitchTown"
                  target="_blank"
                >
                  Medium{" "}
                </a>
                for detailed info. */}
              </div>
            </div>
            <a
              href="https://opensea.io/collection/witchtownwtf"
              target="_blank"
            >
              <img
                src={img1}
                alt=""
                className="img-center text-center img-fluid"
                // data-aos="fade-up"
              />
              {/* <MainSlider /> */}
            </a>

            {/* main button part  */}
            {/* <div className="main-bottom-buttom">
						<div className="left-btn1">
							<button className="left-btn mb-3" data-aos="zoom-in">
								Buy On Opensea
							</button>
						</div>
					</div> */}
            {/* <span className="bottom-text mt-5 mb-5" data-aos="zoom-in">
              Be careful of fake Fox Nation. Use the <br /> button to get the
              right collection.
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
