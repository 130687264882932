import React, { useEffect, useState } from "react";
import { purchaseTokensPlusPurchaseTokensFree } from "../../eth-setup/apis";
import "./Mint.css";
const Mint = () => {
  const [value, setValue] = useState(1);
  const total = value * 100;

  useEffect(() => {
    if (value > 10) setValue(10);
    else if (value < 1) setValue(1);
  }, [value]);

  return (
    <div className="mint-main">
      <div className="container">
        <a name="mint"></a>
        <div className="right col-10 mx-auto p-3">
          <div className="title padding">SOLD OUT</div>
          {/* <div className="right-title mb-3 text-center">Mint Here</div> */}
          <div className="description text-center">
            {/* How many LAC would you like to mint? <br /> */}
            Thanks to all the old witches for the support... 🧙‍♀️ <br />
            {/* 0.0039 ETH the followings (max 10x wallet). <br /> */}
            {/* 666 are reserved for the team. <br /> */}
            {/* <br />5 ETH Raffle: 1 lucky holder will get 5 ETH. */}
            <br /> Raffle will start at the sold-out, no paperhands will
            participate to it. <br />
            Check{" "}
            <a href="https://twitter.com/witch_town_wtf" target="_blank">
              Twitter{" "}
            </a>
            for the rules.
            {/* 2000 reserved for Witches <br />
            2000 free to mint for everyone <br /> (1 free + gas mint per wallet){" "}
            <br /> <br />
            remaining amount 0.01 ETH each <br />
            (max 5 per wallet) <br /> */}
          </div>
          <div className="mint text-center mt-5">
            {/* <div className="mint-title mb-2">{total}/5000</div> */}
            {/* <div className="mint-input d-flex justify-content-center align-items-center">
              <div
                className="btn-minus mx-3"
                onClick={() => setValue(value - 1)}
              >
                <i class="fas fa-minus"></i>
              </div>
              <div className="input mx-3">{value}</div>

              <div
                className="btn-plus mx-3"
                onClick={() => setValue(value + 1)}
              >
                <i class="fas fa-plus"></i>
              </div>
            </div> */}
            {/* <div className="mint-btn mb-3 mt-4">
              <button
                type="button"
                class="btn btn-primary mint-btn-submit"
                onClick={() =>
                  purchaseTokensPlusPurchaseTokensFree(() => {}, value)
                }
              >
                MINT
              </button>
            </div> */}
            {/* <div className="mint-btn mb-3 mt-4">
              <button type="button" class="btn btn-primary mint-btn-submit">
                JUST 4 Witches
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mint;
